import Vuex from 'vuex';
import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import { AuditStoreModule } from '@afrigis/iris-vuex-audit-store';

import { AuthStoreModule } from '@afrigis/iris-vuex-auth-store';

const files = require.context('@/store/modules', false, /\.js$/);
const modules = {};

files.keys().forEach((fileName) => {
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));
  modules[moduleName] = files(fileName).default;
  modules[moduleName].namespaced = true;
});

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    ...modules,
    auditStore: {
      ...AuditStoreModule,
      namespaced: true,
    },
    authStore: {
      ...AuthStoreModule,
      namespaced: true,
    },
  },
  state: {},
  mutations: {},
  actions: {},
});
