<template>
  <b-dropdown
    class="mt-2 mr-2 base-layer-selector"
    no-caret
    right
    toggle-class="text-decoration-none"
    variant="primary"
  >
    <template #button-content>
      <b-icon-layers-half />
    </template>
    <h6 class="mb-2 font-weight-bold text-center">Map Backgrounds</h6>
    <b-dropdown-item
      :active="isActive(mapType)"
      @click="switchLayer(mapType.value)"
      :key="`map-type${index}`"
      v-for="(mapType, index) in MapTypes"
      size="sm"
    >
      <span class="col-6 p-0">
        <img :alt="mapType.text" :src="mapType.thumbnail" />
      </span>
      <span class="col-6 p-0 pl-1"> {{ mapType.text }} </span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import {
  BDropdown,
  BDropdownItem,
  BIconLayersHalf,
} from 'bootstrap-vue';

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BIconLayersHalf,
  },
  computed: {
    ...mapGetters({
      MapTypes: 'baseMaps/MapTypes',
      selectedMapType: 'baseMaps/SelectedMapType',
    }),
  },
  data() {
    return {
      currentType: null,
    };
  },
  methods: {
    ...mapMutations({
      setMapTypes: 'baseMaps/SelectedMapType',
    }),
    isActive(mapType) {
      return mapType.value === this.currentType;
    },
    switchLayer(layer) {
      this.currentType = layer;
      this.setMapTypes(
        this.MapTypes.find((layerType) => layerType.value === layer),
      );
    },
  },
  mounted() {
    this.currentType = this.selectedMapType.value;
  },
  name: 'LayerSelector',
};
</script>
<style>
.base-layer-selector {
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 500;
  cursor: pointer;
}
.dropdown-item.active, .dropdown-item:active {
    color: rgb(0, 0, 0) !important;
    text-decoration: none !important;
    background-color:#dae0e5 !important;
}
</style>
