<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Map Backgrounds</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button @click="shareViaWebShare" title="Share This App" variant="outline-secondary">
          <b-icon-share />
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk">
          <b-form-textarea
            id="fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          ></b-form-textarea>
          <template #modal-footer="{ ok}">
            <b-button
              size="sm"
              variant="primary"
              @click="ok()">
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>

        <b-button @click="startTour" variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <v-tour class="mapTour" name="baseMapTour" :options="tourOptions" :steps="baseMapTourSteps" />
    <contact-expert/>
    <b-row>
      <b-col md="8">
        <layer-selector class="mapTypes" v-if="selectedMapType" />
        <div id="leaflet-map"
          class="mb-3">
          <leaflet-map />
        </div>
      </b-col>
      <b-col md="4" class="mt-md-0 mt-sm-2">
        <base-map-description id="base-map-description" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import VueTour from 'vue-tour';
import { mapGetters, mapMutations } from 'vuex';

import BaseMapDescription from '@/components/BaseMapDescription.vue';
import {
  BButton,
  BCol,
  BContainer,
  BFormTextarea,
  BIconShare,
  BRow,
} from 'bootstrap-vue';
import LeafletMap from '@/components/LeafletMap.vue';
import LayerSelector from '@/components/LayerSelector.vue';
import oidcAuth from '@/mixins/oidcAuth';
import ContactExpert from '@/components/ContactExpert.vue';

Vue.use(VueTour);

export default {
  name: 'BaseMap',
  components: {
    BaseMapDescription,
    BButton,
    BCol,
    BContainer,
    BFormTextarea,
    BIconShare,
    BRow,
    ContactExpert,
    LayerSelector,
    LeafletMap,
  },
  computed: {
    ...mapGetters({
      MapTypes: 'baseMaps/MapTypes',
      selectedMapType: 'baseMaps/SelectedMapType',
    }),
    shareUrl() {
      const { origin, pathname } = window.location;
      return `${origin}${pathname}?mapType=${this.selectedMapType.value}`;
    },
    baseMapTourSteps() {
      return [
        {
          content:
            'A map background is the canvas used to display data on. The map background usually features content that do not change as often. Ensure you select the background best suited to your needs.',
          header: {
            title: 'Map Background',
          },
          params: {
            placement: 'auto',
          },
          target: '#leaflet-map',
        },
        {
          content:
            'Select the map background best suited to your needs to display your content on.',
          header: {
            title: 'Select Map Background',
          },
          params: {
            placement: 'auto',
          },
          target: '.mapTypes',
        },
        {
          content:
            'Move around the map area by left-clicking and dragging the mouse. Alternatively, use up, down, left and right keys to move around the map area.',
          header: {
            title: 'Move around the map',
          },
          params: {
            placement: 'auto',
          },
          target: '#leaflet-map',
        },
        {
          content:
            'Zoom in (+) to see more detail or out (-) to see an overview of specific the area, or use the mouse wheel to zoom.',
          header: {
            title: 'Changing your zoom level',
          },
          params: {
            placement: 'auto',
          },
          target: '#leaflet-map',
        },
        {
          content: 'Detailed description of the different map backgrounds and their uses.',
          header: {
            title: 'Detailed Description',
          },
          params: {
            placement: 'bottom',
          },
          target: '#base-map-description',
        },
      ];
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: false,
      };
    },
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    ...mapMutations({
      setSelectedMapType: 'baseMaps/SelectedMapType',
    }),
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator.share({
          title: document.title,
          url: this.shareUrl,
        }).then().catch(() => {
        // Handle the error!
        });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startTour() {
      this.$tours.baseMapTour.start();
    },
    switchMapType(mapType) {
      const type = this.MapTypes.find((t) => t.value === mapType);
      if (type) {
        this.setSelectedMapType(type);
      }
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(localStorage.getItem('hasSeenBaseMapsTour'));
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenBaseMapsTour', true);
      }
    },
  },
  mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    const { mapType } = this.$route.query;
    if (mapType) {
      this.switchMapType(mapType.toLowerCase());
    }
    this.tourConfigure();
  },
  mixins: [oidcAuth],
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
#leaflet-map {
  position: relative;
  width: 100%;
  height: 75vh;
}
#fallback{
  pointer-events: none;
  background-color: lightgrey;
}
.mapTypes {
  position: absolute;
  top: 4px;
  right: 19px;
  z-index: 500;
  cursor: pointer;
}
.mapTour {
  z-index: 500;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}
</style>
