<template>
  <div :id="mapId" class="mapContainer">
    Loading Map...
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      selectedMapType: 'baseMaps/SelectedMapType',
    }),
    center: () => [-25.808611, 28.256111],
    mapId: () => 'AGmap',
  },
  data() {
    return {
      mapLoaded: false,
      map: null,
    };
  },
  methods: {
    checkIfMapLoaded() {
      this.mapLoaded = !!window.AfriGIS;
      if (this.mapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfMapLoaded();
      }, 500);
    },

    initMap() {
      if (this.map) {
        return;
      }
      this.map = window.AfriGIS.map(this.mapId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.selectedMapType.value,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 10,
        zoomControl: true,
      });
      this.map.zoomControl.setPosition('bottomright');
    },
  },
  name: 'LeafletMap',
  mounted() {
    this.checkIfMapLoaded();
  },
  watch: {
    mapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    selectedMapType(newV) {
      this.map.switchTo(newV.value);
    },
  },
};
</script>

<style>
  .mapContainer {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .leaflet-bar a{
    background-color: var(--primary) !important;
    color: #fff !important;
  }
</style>
