import Vue from 'vue';
import VueRouter from 'vue-router';
import BaseMap from '@/views/BaseMap.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/map-backgrounds',
    name: 'BaseMaps',
    component: BaseMap,
  },
  {
    component: () => import(/* webpackChunkName: "Error" */'@/views/Error.vue'),
    meta: {
      isPublic: true,
    },
    name: 'Error',
    path: '/error',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
