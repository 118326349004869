<template>
  <div>
    <b-card id="trust-us-card"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      text-variant="white"
      class="mb-3">
      <div class="content">
        <h5>Trust us for</h5>
        <ul>
          <li>SABS and ISO compliant Address data.</li>
          <li>Accurate and verified address results returned.</li>
          <li>
            Components available via API to integrate into your own solutions.
          </li>
          <li>Over 50 Million searchable address combinations.</li>
        </ul>
      </div>
    </b-card>
    <b-card
      class="remove-border-card"
      v-if="!!SelectedMapType">
      <b-row>
        <b-icon-layers-half font-scale="2" class="border bg-primary p-1" variant="light" />
        <span>
          <h4 class="m-1">{{ header }}</h4>
        </span>
      </b-row>
      <b-card-text v-if="SelectedMapType.description">
        {{ SelectedMapType.description }}
      </b-card-text>
      <h5>
        {{ SelectedMapType.uses.text }}
      </h5>
      <ul>
        <li :key="`use-${index}`" v-for="(use, index) in uses">
          {{ use }}
        </li>
      </ul>
      <h5>
        {{ SelectedMapType.availability.text }}
      </h5>
      <ul>
        <li :key="`avail-${index}`" v-for="(avail, index) in availability">
          {{ avail }}
        </li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BCard,
  BCardText,
  BIconLayersHalf,
  BRow,
} from 'bootstrap-vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

export default {
  components: {
    BCard,
    BCardText,
    BIconLayersHalf,
    BRow,
  },
  computed: {
    ...mapGetters({
      SelectedMapType: 'baseMaps/SelectedMapType',
    }),
    backgroundImage: () => backgroundImage,
    availability() {
      return this.SelectedMapType.availability.values;
    },
    header() {
      return !this.SelectedMapType ? '' : `${this.SelectedMapType.text} Map background`;
    },
    uses() {
      return this.SelectedMapType.uses.values;
    },
  },
  name: 'BaseMapDescription',
};
</script>

<style>
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom: 0;
  border-color: white;
}

#trust-us-card .content {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.remove-border-card {
  border-top: 0;
  border-color: white;
}
</style>
