const vectorStandard = require('@/assets/mapBgControlls/vectorStandard.png');
const vectorGrey = require('@/assets/mapBgControlls/vectorGrey.png');
const vectorGreen = require('@/assets/mapBgControlls/vectorGreen.png');
const vectorBlack = require('@/assets/mapBgControlls/vectorBlack.png');
const vectorSatellite = require('@/assets/mapBgControlls/vectorSatellite.png');

const state = {
  mapTypes: [
    {
      availability: {
        text: 'Additional vector map backgrounds available:',
        values: [
          'Grey',
          'Green',
          'Black',
        ],
      },
      description: 'This Vector map background is the canvas your data will be displayed on. It uses different colours to differentiate between roads, dams and parks. The backgrounds are available in different colours, depending on your needs.  Map backgrounds can change the way you see things. Make sure you choose the best option for your end goal.',
      uses: {
        text: 'Use Vector Standard map background when you:',
        values: [
          'want to save time with faster map loads',
          'don’t need to examine the landscape on the map',
          'don’t have data layers you want to layer over the map background',
        ],
      },
      text: 'Vector Standard',
      value: 'vector',
      thumbnail: vectorStandard,
    },

    {
      availability: {
        text: 'Additional vector map backgrounds available:',
        values: [
          'Standard',
          'Green',
          'Black',
        ],
      },
      description: 'A Vector Grey map background is ideal to overlay additional data over your vector map. Data shows up clearly. Use the vector map as a point of reference for your superimposed data.',
      uses: {
        text: 'Use Vector Grey map background when you:',
        values: [
          'want to save time with faster map loads',
          'need to showcase data on a map',
          'don’t need to examine the landscape on the map using satellite imagery',
        ],
      },
      text: 'Vector Grey',
      value: 'vector-grey',
      thumbnail: vectorGrey,
    },

    {
      availability: {
        text: 'Additional vector map backgrounds available:',
        values: [
          'Standard',
          'Grey',
          'Black',
        ],
      },
      description: 'A Vector Green map background is ideal to overlay additional data over your vector map. Data shows up clearly. Use the vector map as a point of reference for your superimposed data.',
      uses: {
        text: 'Use Vector Green map background when you:',
        values: [
          'want to save time with faster map loads',
          'need to showcase data on a map (keep in mind, only certain colours and complexity level of data will work on this kind of map)',
          'don’t need to examine the landscape on the map using satellite imagery',
        ],
      },
      text: 'Vector Green',
      value: 'vector-green',
      thumbnail: vectorGreen,
    },

    {
      availability: {
        text: 'Additional vector map backgrounds available:',
        values: [
          'Standard',
          'Grey',
          'Green',
        ],
      },
      description: 'A Vector Black map background is ideal to overlay additional data over your vector map. Data shows up clearly. This type of map background is used ideally to showcase point and line type of data. Avoid if possible when using colour shapes over the black map background, as the colours tend to get muddled.',
      uses: {
        text: 'Use Vector Black map background when you:',
        values: [
          'want to save time with faster map loads',
          'need to showcase data on a map (keep in mind, only certain colours and complexity level of data will work on this kind of map)',
          'don’t need to examine the landscape on the map using satellite imagery',
        ],
      },
      text: 'Vector Black',
      value: 'vector-black',
      thumbnail: vectorBlack,
    },

    {
      availability: {
        text: 'Currently available in:',
        values: [
          'Most aesthetic (cloud cover edited out)',
        ],
      },
      description: 'The Satellite and Vector map background displays imagery made up of multiple satellite images, as well as overlaid vector data. This type of map background shows a high level of detail and context. Please consider that these hybrid base maps are large files. It might take longer to load on the map and cause increased data usage.',
      uses: {
        text: 'Use Satellite and Vector Map background when you:',
        values: [
          'need to examine the landscape on the map along with the data',
          'require to view changes over time in the landscape',
          'need to confirm property boundaries',
          'need to accurately place data on a map by using satellite imagery to confirm locations',
        ],
      },
      text: 'Satellite and Vector',
      value: 'hybrid',
      thumbnail: vectorSatellite,
    },
  ],
  selectedMapType: null,
};

const getters = {
  MapTypes: (stateP) => stateP.mapTypes,
  SelectedMapType: (stateP) => stateP.selectedMapType,
};

const actions = {
};

const mutations = {
  SelectedMapType: (stateP, type) => {
    const localState = stateP;
    localState.selectedMapType = type;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
