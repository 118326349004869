<template>
  <div>
    <b-alert
      class="container container-between-stickies"
      show
      variant="info"
      v-if="!OidcUser"
    >
      <b-spinner label="Loading..." small />
      Loading User Information
    </b-alert>
    <router-view class="container-between-stickies" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { BAlert, BSpinner } from 'bootstrap-vue';

import { AuthMixin } from '@afrigis/iris-vuex-auth-store';

export default {
  components: {
    BAlert,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      MapTypes: 'baseMaps/MapTypes',
      OidcUser: 'authStore/OidcUser',
      SelectedMapType: 'baseMaps/SelectedMapType',
    }),
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    ...mapActions({
      AuditWrite: 'auditStore/Log',
    }),
    ...mapMutations({
      SetSelectedMapType: 'baseMaps/SelectedMapType',
    }),
  },
  mounted() {
    /**
     * Set the selectType to vector,
     * Set it only when it is null, the user might have already selected
     * a different type via the request query param
     */
    if (!this.SelectedMapType) {
      const type = this.MapTypes.find((t) => t.value === 'vector');
      this.SetSelectedMapType(type);
    }
    this.AuditWrite(this.OidcUser.email);
  },
  mixins: [AuthMixin],
};
</script>

<style>

</style>
